import {
  A,
  cache,
  createAsync,
  useIsRouting,
  useNavigate,
  useParams,
} from "@solidjs/router";
import {
  Accessor,
  createMemo,
  createSignal,
  For,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import { editIcon } from "~/assets/assets";
import { getGiftById } from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const isRouting = useIsRouting();

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const navigateToEdit = (side: string) => {
    if (latestGiftCardDetails()) {
      navigate(`/gifts/${params.giftId}/edit`, {
        state: { side },
      });
    }
  };

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="flex h-full w-full flex-col overflow-y-auto bg-plutoLoginBgMob bg-cover bg-no-repeat text-white md:w-[800px]">
        <div class="mb-12 p-4">
          <A
            href={`/gifts/${params.giftId}`}
            class="mb-4 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryMedium bg-[#010101]"
          >
            <PhosphorIcon
              name="arrow-left"
              fontSize={20}
              size="bold"
              class={`cursor-pointer text-baseTertiaryLight`}
            />
          </A>
          <p class="text-smallBold uppercase tracking-[1.1px]">
            Created by you
          </p>
          <Show
            when={!isRouting() || !giftCardDetails()}
            fallback={<DottedLoader color="#999" />}
          >
            <h1 class="text-[28px] font-semibold">
              {giftCardDetails()?.title}
            </h1>
            <div class="mt-12 flex flex-col gap-6">
              <div class="flex items-center gap-2">
                <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
                  The gift
                </p>
                <hr class="w-full border border-t border-basePrimaryMedium" />
              </div>
              <img
                class="h-28 w-48 rounded-md"
                src={
                  giftCardDetails()?.content.images.occasionImages[0].imageUrl
                }
                alt={giftCardDetails()?.title}
              />
              <p class="text-normal text-baseSecondaryLight">
                This gift features thoughtful and unforgettable experiences
                curated by Pluto, based on your inputs, for you to share with{" "}
                {giftCardDetails()?.content.text.occasion.recipientName}. It
                includes:
              </p>
              <div class="flex flex-col gap-2.5">
                <For each={giftCardDetails()?.content.text.activitySlots}>
                  {(activity, index) => {
                    return (
                      <div class={`flex items-center gap-2.5`}>
                        <img
                          src={
                            giftCardDetails()?.brandDetails.find(
                              (brand) =>
                                brand.brandKey ===
                                activity.activities[
                                  Number(
                                    giftCardDetails()?.content.selection
                                      .activities[index() + 1]
                                  ) - 1
                                ].brandKey
                            )?.logoUrl
                          }
                          alt=""
                          class="h-10 w-10 rounded-full"
                        />
                        <p class={`text-normal text-white`}>
                          {
                            activity.activities[
                              Number(
                                giftCardDetails()?.content.selection.activities[
                                  index() + 1
                                ]
                              ) - 1
                            ]?.description
                          }
                        </p>
                      </div>
                    );
                  }}
                </For>
              </div>
              <div class="flex gap-1">
                <img src={editIcon} alt="edit icon" class="h-5 w-5" />
                <button
                  onClick={() => navigateToEdit("back")}
                  class="cursor-pointer text-subtitleSmall underline"
                >
                  Edit gift
                </button>
              </div>
            </div>
            <div class="mt-12 flex flex-col gap-6">
              <div class="flex items-center gap-2">
                <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
                  The message
                </p>
                <hr class="w-full border border-t border-basePrimaryMedium" />
              </div>
              <img
                class="h-28 w-32 rounded-md"
                src={
                  giftCardDetails()?.content.images.activityImages[0].imageUrl
                }
                alt={giftCardDetails()?.title}
              />
              <p class="text-normal text-baseSecondaryLight">
                {giftCardDetails()?.content.text.occasion.occasionTitle}
              </p>
              <p class="text-normal text-baseSecondaryLight">
                {giftCardDetails()?.content.text.occasion.wishMessage}
              </p>
              <div class="flex gap-1">
                <img src={editIcon} alt="edit icon" class="h-5 w-5" />
                <button
                  onClick={() => navigateToEdit("front")}
                  class="cursor-pointer text-subtitleSmall underline"
                >
                  Edit message
                </button>
              </div>
            </div>
          </Show>
          <div class="mt-12 flex flex-col gap-6">
            <div class="flex items-center gap-2">
              <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
                How this gift works?
              </p>
              <hr class="w-full border border-t border-basePrimaryMedium" />
            </div>
            <p class="text-normal text-baseSecondaryLight">
              The receiver can choose to enjoy one or all experience curated in
              this gift. More details in next step.
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-3 border-t border-basePrimaryDark p-4">
          <button class="text-buttonMedium underline">
            Receiver’s preview
          </button>
          <A
            href={`/gifts/${params.giftId}/disclaimer`}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">
              Next : Top up gift balance
            </p>
          </A>
        </div>
      </div>
    </Suspense>
  );
}
